/* Landing */
.landing-page {
	.navbar {
		padding: 2rem 0;
		box-shadow: none;
		-webkit-box-shadow: none;

		.navbar-brand {
			img {
				width: 145px;
			}
		}

		.navbar-collapse {
			@media (max-width: 991px) {
				display: block;
			}

			&.collapse {
				display: none;
			}

			&.show {
				display: block;
			}

			.navbar-nav {
				.nav-item {
					.nav-link {
						color: $white;
						padding-left: 1rem;
						padding-right: 1rem;
						font-size: 1rem;
						font-family: $type-1;
						text-align: left;
						@media (min-width: 992px) {
							margin-left: 1rem;
							margin-right: 1rem;

							&:first-child {
								margin-left: 0;
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}

	.top-banner {
		background: url("../images/samples/landing/banner-bg.svg");
		background-size: cover;

		.demo-image {
			img {
				max-width: 100%;
				@media (min-width: 992px) {
					position: absolute;
					left: -80px;
				}
				@media (max-width: 991px) {
					margin-top: 1rem;
					max-width: 100%;
				}
			}
		}

		.top-banner-content {
			padding-top: 80px;
			padding-bottom: 120px;
		}
	}

	.middle-section {
		padding-top: 80px;
		padding-bottom: 80px;
		text-align: center;
		color: $black;

		.card {
			background: $white;
			border-radius: 10px;
			box-shadow: 5px 5px 25px 0 rgba(46,61,73,.2);
			border: 1px solid $border-color;
			@include transition(box-shadow 0.5s ease);

			&:hover {
				box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
			}

			&.card-icon-top {

				.card-body {
					padding: 3rem;
				}

				.card-icon {
					width: 80px;
					height: 80px;
					margin-bottom: 2rem;
				}

				h4 {
					margin-bottom: 2rem;
				}
			}

			&.card-item-preview {
				position: relative;

				.badge {
					position: absolute;
					top: -25px;
					right: -35px;
					width: 65px;
					height: 65px;
					padding: 1rem;
					border-radius: 50%;
					line-height: 32px;
					font-size: 1rem;
				}

				.card-img-top {
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}
	}

	.features-section {
		background: $content-bg;
		text-align: center;
		color: $black;
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.newsletter-section {
		padding: 40px;
		background: $bluish-gradient;
		.form-control {
			background: transparent;
			max-width: 400px;
		}
	}

	.footer {
		background: $white;
		margin: 0;
		width: 100%;
		padding: 80px 0 0;

		.footer-top {
			border-bottom: 1px solid rgba($white, .2);

			.brand-logo {
				width: 145px;
			}

			.footer-social-icons {
				margin-top: 3rem;

				i {
					width: 43px;
					height: 43px;
					font-size: 1rem;
					border-radius: 100%;
					text-align: center;
					line-height: 43px;
					margin-right: 0.5rem;
				}
			}

			.contact-details {
				i {
					font-size: 1.25rem;
					font-weight: 500;
				}
			}

			.nav {
				display: block;
				.nav-item {
					.nav-link {
						color: $body-color;
						font-size: 0.9375rem;
						font-family: $type-1;
						line-height: 1;
						font-weight: normal;
					}
					&:first-child {
						.nav-link {
							padding-top: 0;
						}
					}
				}
			}

			.form-control {
				background: lighten($black, 9%);
				border: 2px solid darken($white, 50%);
				border-radius: 1.25rem;
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;
			}
		}

		.footer-bottom {
			background: $content-bg;
			font-family: $type-1;
		}
	}
}